import * as React from "react";

const SvgLanguage = (props) => (
  <svg
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 600 600"
    {...props}
  >
    <path
      d="M329 584.27a8.72 8.72 0 0 1-6.47-2.89 8.46 8.46 0 0 1-2.15-6.62c5.36-52.21-11-86.7-15.5-95.1-9.46 1.72-44.65 7.76-71.9 7.76-19.87 0-31.64-3.16-36-9.67-27-40.35-53.18-125.1-60.07-168.1-2.19-13.7 6-42.91 21.9-78.13A559.53 559.53 0 0 1 227 119.05c34.53-43.86 89.1-97.18 153-102.18 1.74-.14 3.48-.21 5.18-.21 15.87 0 29.85 6 41.55 17.7 10.53 10.56 19.31 26 26.11 45.74 25 72.92 17.19 182.78 12.65 226.63-3.92 37.94-10.1 74.6-17.39 103.22-5.28 20.76-13.69 47.14-23.7 54.94-9.4 7.31-28.3 8.85-42.5 8.85-3.44 0-6.36-.09-8.44-.18-1.57 16-9.8 83.26-38.8 108.59a8.37 8.37 0 0 1-5.66 2.12Z"
      style={{
        fill: "#fff",
      }}
    />
    <path
      d="M385.12 20.09c128.62 0 76.63 411.33 37.11 442.1-8.87 6.9-28 8.12-40.39 8.12-6.78 0-11.55-.36-11.55-.36s-6.31 82-37.94 109.62a5 5 0 0 1-3.33 1.27 5.2 5.2 0 0 1-5.2-5.73c6.35-62-17.15-99.26-17.15-99.26S264.64 484 233 484c-15.83 0-29.05-2-33.14-8.14-26.46-39.55-52.53-123-59.53-166.75s105-278.26 239.88-288.81c1.66-.13 3.31-.2 4.91-.2m0-6.86c-1.78 0-3.62.08-5.45.22-65.1 5.09-120.43 59.09-155.38 103.48a563 563 0 0 0-68.57 113.18c-7.77 17.21-13.93 33.57-17.81 47.3-4.07 14.4-5.53 25.42-4.35 32.78 3.69 23 12.19 55.21 22.75 86.07 12.11 35.4 25.55 65 37.85 83.4 5.11 7.63 17.45 11.19 38.84 11.19 25.86 0 57.86-5.2 70-7.36a128.54 128.54 0 0 1 8.2 21.27c4.37 14.88 8.91 39 5.77 69.65a11.86 11.86 0 0 0 3 9.27 12.14 12.14 0 0 0 9 4 11.91 11.91 0 0 0 7.84-3c14.22-12.43 25.33-35.57 33-68.79a350.77 350.77 0 0 0 6.61-38.84c1.58 0 3.39.07 5.37.07 21.44 0 36.45-3.22 44.6-9.57 4.68-3.64 9.1-10.54 13.52-21.08a261.61 261.61 0 0 0 11.41-35.72c7.33-28.78 13.53-65.62 17.47-103.72 4.35-42 5.82-83.85 4.26-121.06-1.81-43.27-7.55-79.29-17.08-107-7-20.29-16-36.12-26.92-47-12.37-12.41-27.17-18.71-44-18.71Z"
      style={{
        fill: "#231f20",
      }}
    />
  </svg>
);

export default SvgLanguage;
